const technologies = [
    { id: 'iot', title: '', name: 'Internet of Things', split: '', color: '#fff', link: 'How-The-Internet-of-Things-will-Complicate-Incident-Response.png' },
    { id: 'da', title: 'Data Analytics',  split: '', link: 'big-data-definition.jpg' },
    { id: 'cs', title: '',  name: 'Cybersecurity', split: '', link: 'cybersecurity.png' },

    { id: 'ai', title: '',  name: 'Artificial Intelligence', split: '', link: 'ai.png' },
    { id: 'rt', title: 'Retail Technology',  split: 'Retail', link: 'busting mobile 1536x1536_Original.jpg' },
    { id: 'its', title: 'Intelligent Transportation',  split: 'Intelligent', link: 'wirelesscomm.png' },

    { id: 'sm', title: 'Social Media',  split: 'Social', link: 'social.png' },
    { id: 'cc', title: 'Cloud Computing',  split: 'Cloud', link: 'microservices.png' },
    { id: 'lbs', title: 'Location Based Systems',  split: 'Location Based', link: 'lbs.png' },

    { id: 'va', title: 'Video Analytics',  split: '', link: 'va.jpg' },
    { id: 'bc', title: '',  name: 'Blockchain', split: '', link: 'blockchain.jpeg' },
  ]

  const topicDescriptions = [
    { technology: 'iot', description:
    `<p>The <b>Internet of things</b> is the extension of Internet connectivity into physical devices
    and everyday objects. Embedded with electronics, Internet connectivity, and other forms of
    hardware, these devices can communicate and interact with others over the Internet, and they
    can be remotely monitored and controlled.</p>`},

    { technology: 'da', description:
    `<p><b>Data analytics</b> produces information from a large collection of data sets (big data).  These are
    so large and complex that it becomes difficult to process using traditional
    database management tools or data processing applications. The challenges include capture,
    curation, storage, search, sharing, analysis, and visualization. The push for big data stems
    from the rich information which can be derived from a single large set of related data, as
    compared to a collection of smaller sets with the same total amount of data.
    The promise of data analytics is the ability to spot business trends, determine quality of research,
    prevent diseases, combat crime, and determine real-time roadway traffic conditions.</p>`},

    { technology: 'cs', description:
    `<p>Computer security, <b>cybersecurity</b> or information technology security is the protection of computer systems
    from theft or damage to their hardware, software or electronic data, as well as from disruption or misdirection of
    the services they provide.</p>`},

    { technology: 'ai', description:
    `<p>In computer science, <b>artificial intelligence</b>, sometimes called machine intelligence, is intelligence
    demonstrated by machines, in contrast to the natural intelligence displayed by humans.</p>`},

    { technology: 'rt', description:
    `<p><b>Retail technology</b> encompasses a wide spectrum of solutions which have evolved since the early days of Point of Sale (POS) systems.
    Most everyone these days is familiar with Amazon and all of the technology utilized to make shopping easier:
    customization, personalization, advanced search, delivery options and much more.  A recent article describes <a
    href="https://www.retaildive.com/news/5-retail-technology-trends-to-watch-in-2019/545662/"
    target="_blank" rel="noopener noreferrer">5 retail technology trends to watch for in 2019</a>.
    </p>`},

    { technology: 'its', description:
    `<p><b>Intelligent transportation systems</b> has been a major initiative of the US Government since 1991.  Forty years after
    President Eisenhower launched the interstate highway system, Congress funded the addition of intelligent systems to the existing
    highway network to increase the capacity and efficiency of our nation's roadway.  The field encompasses advanced traveler information,
    advanced traffic management, intermodal transportation (including public transit), and vehicle-to vehicle and vehicle-to-intersection
    communidation for increased safety and throughput.</p>`},

    { technology: 'sm', description:
    `<p>
    Facebook, Twitter, Linkedin, Foursquare, and Pinterest are examples of <b>Social Media</b>. Build upon the foundation of Web 2.0,
    social media adds interactions among viewers to traditional media, allowing users to <b>create, share, and exchange information
    and ideas in virtual communities and networks</b>.  All of these examples of social media have the common thread of establishing
    community and exchanging media, ideas, and opinions.  As with anything, there are accolades and criticisms of the usage of this
    type of communication.
    </p>`},

    { technology: 'cc', description:
    `<p>
    <b>Cloud computing</b> is the use of computing resources (hardware and software) that are delivered as a service over a network
    (typically the Internet via web browsers). The name comes from the use of a cloud-shaped symbol to represent a large, complex
    infrastructure (see the Wikipedia article for an example). Cloud computing entrusts remote services with a user's data, software
    and computation.  gMail, Google Drive, Apple's iCloud, Amazon EC2 are popular cloud computing platforms.  Traditional desktop
    computing such as Microsoft Office have also migrated to the cloud.  One popular term for accessing software packages like
    Salesforce is Software as a Service (SaaS).
    </p>`},

    { technology: 'lbs', description:
    `<p><b>Location Based Services (LBS)</b>
    are a general class of computer-based services whose results change as a function of <b>time</b> and <b>location</b>.
    LBS has a number of uses in Social Networking, entertainment and commercial services, all of which are accessible with
    mobile devices through their mobile network, using information on the geographical position of the mobile device.</p>
    <p>LBS originated from the need to locate a person in an emergency.  LBS services include discovering the nearest banking
    cash machine or the whereabouts of a friend or employee. LBS include parcel tracking and vehicle tracking services.
    LBS-enabled mobile commerce offers coupons or advertising directed at customers (or potential customers) based on their
    current location. They include personalized weather services and even location-based games.</p>`},

    { technology: 'va', description:
    `<p><b>Video Analytics</b> is the automatic analysis of video to detect and determine temporal events not based on a single image.
    It is essentially trying to mimic the brain as it analyzes contiguous frames of video.  This can be useful in entertainment,
    health-care, retail, automotive applications, transportion, home automation, safety and security. The heart of video analytics
    are algorithms (sequences of steps) that can be implemented as software on general purpose machines, or as hardware in specialized
    video processing units.</p>
    <p>Applications of video analytics include dynamic masking,
    motion detection, object detection, face recognition, automatic license plate recognition, tamper detection, and video tracking.
    Challenges to good video analysis include video noise, image stability, blurred masking, super-resolution and dynamic ambient lighting.
    </p>`},

    { technology: 'bc', description:
    `<p><b>Blockchain</b> technology enables distributed public ledgers that hold immutable data in a secure and encrypted
    way and ensure that transactions can never be altered. While Bitcoin and other cryptocurrencies are the most popular examples
    of blockchain usage, this “distributed ledger technology” (DLT) is finding a broad range of uses. Data storage, financial
    transactions, real estate, asset management and many more uses are being explored.</p>`}

  ]

  const topicLinks = [
    { technology: 'iot', link: 'https://en.wikipedia.org/wiki/Internet_of_things'},
    { technology: 'da', link: 'http://en.wikipedia.org/wiki/Big_Data'},
    { technology: 'cs', link: 'https://en.wikipedia.org/wiki/Computer_security'},

    { technology: 'ai', link: 'https://en.wikipedia.org/wiki/Artificial_intelligence'},
    { technology: 'rt', link: 'https://en.wikipedia.org/wiki/Association_for_Retail_Technology_Standards'},
    { technology: 'its', link: 'https://en.wikipedia.org/wiki/Intelligent_transportation_system'},

    { technology: 'sm', link: 'http://en.wikipedia.org/wiki/Social_media'},
    { technology: 'cc', link: 'http://en.wikipedia.org/wiki/Cloud_computing'},
    { technology: 'lbs', link: 'http://en.wikipedia.org/wiki/Location-based_service'},

    { technology: 'va', link: 'http://en.wikipedia.org/wiki/Video_analytics'},
    { technology: 'bc', link: 'https://en.wikipedia.org/wiki/Blockchain'}
  ];

  module.exports = {
    'technologies': technologies,
    'topicDescriptions' : topicDescriptions,
    'topicLinks': topicLinks
  }