import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import TechIcons from './tech-icons';
import topics from './topics';

const Home = ({
      topicData = null,
      toAdd = () => {}
    }) => {
    const [technologies, setTechnologies] = useState(topics.technologies);
    const [descriptions, setDescriptions] = useState(topics.topicDescriptions);
    const [links, setLinks] = useState(topics.topicLinks);
    const [groups, setGroups] = useState([]);
    const [ttips, setTtips] = useState([]);

    useEffect(() => {
      const makeGroup = (topicTechnologies, start, finish) => {
        return topicTechnologies.reduce((code, t, i) => {
          if (i >= start && i < finish) {
            const ttId = `ttip${i}`;
            code.push((<li key={i} data-tip data-for={ttId}>{t.name || t.title}</li>));
          }
        return code;
        }, [])
      };

      const newGroups = [];
      const half = Math.ceil(technologies.length / 2);
      newGroups.push(makeGroup(technologies, 0, half));
      newGroups.push(makeGroup(technologies, half, half * 2));

      setGroups(newGroups);

      if (topicData && technologies && links && descriptions) {
        console.log(`topics data`, topicData)
        if (topicData && topicData.count > 0 &&
            topicData.technologies &&
            topicData.technologies.length === topicData.count &&
            topicData.descriptions.length === topicData.count &&
            topicData.links.length === topicData.count
            ) {
                console.log(`no adds`)
                setTechnologies(topicData.technologies);
                setDescriptions(topicData.descriptions);
                setLinks(topicData.links);
        } else {
            // add topics to server
            toAdd('technologies', technologies);
            setTechnologies(technologies);
            toAdd('topicdescriptions', descriptions);
            setDescriptions(descriptions);
            toAdd('topiclinks', links);
            setLinks(links);
        }
      }

      const makeTtips = [];
      for (const [index, value] of technologies.entries()) {
        const ttId = `ttip${index}`
        const idCode = value.id;
        const description = descriptions.find(d => d.technology === idCode).description || ''
        const link = links.find(d => d.technology === idCode).link || ''
        makeTtips.push(
          <ReactTooltip id={ttId} key={index} html={true} delayHide={100} effect="solid" border={true}
              clickable={true} eventOff="focusout" className="tooltip-item" type='error'>
            { `${description}  <p>See the  <a href=${link} target='_blank'>
            Wikipedia</a> article for more information.</p>` }
          </ReactTooltip>
        )
      }
      setTtips(makeTtips);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topicData, toAdd]);

    return (
      <><div className="two-column-panel">
        <div className="text-segment">
          <h2>What's the impact of technology on your world?</h2>
          <p>
          Is your small business facing a technology challenge?
          Does your web site need a fresh approach?  Starting up a new business and
          need expertise to architect the technology that will drive your
          success?
          Does your large enterprise face new challenges in the market?
          Do you need the computing resource
          elasticity offered by the "cloud"?
          Are you overwhelmed with data and still lacking information?
          Are you a commercial web/mobile provider that needs some systems engineering, a
          new architecture, and good design principles?
          </p>
          <p>
          Have you considered that there might be a
          technology solution? Do you need technology advice?
          Would added technology expertise provide a solution?
          Do you need software development expertise?
          </p>
        </div>
        <div className="text-segment">
          <h2>
          Fueled by technology advances,
          </h2>
          <p>
          the promise of new products and services is:
          improved business performance, increased sales and market capture,
          a better quality of life,
          savings of time, greater security, improved health, more fun and more
          opportunities to connect with an ever-expanding social circle.
          </p>
          <p className="short-height" style={{display:'none'}}>Whatever your technology need is, we have resources to help you.
          Short-term or long-term, we can provide you technology solutions.
          </p>
        </div>
        <div className="text-segment tall-height">
          <h2>
          In {new Date().getFullYear()}, these technologies are impacting our world:
          </h2>
          <div className="narrow-width">
            <ul>
              {groups[0]}
            </ul>
            <ul>
              {groups[1]}
            </ul>
          </div>
          {ttips}
          <div className="wide-width">
          <TechIcons className="wide-width" technologies={technologies}/>
          </div>
          <p>Whatever your technology need is, we have resources to help you.
          Short-term or long-term, we can provide you technology solutions.
          </p>
        </div>
      </div>
      </>
    );
}

export default Home;