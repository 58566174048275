import React, { Component } from 'react';
import Icon from './icon'

class TechIcons extends Component {

    constructor(props) {
      super(props)
      this.technologies = props.technologies
    }

    render() {
      const icons = []
      for (const [index, value] of this.technologies.entries()) {
        icons.push(<Icon key={index} id={index} item={value} ></Icon>)
      }
      return(
          <div className="tech-icons">
            {icons}
          </div>
      )
    }
}

export default TechIcons;
