// import topics from '../segments/topics'

class DataService {

    constructor() {
        this.path = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : window.location.hostname
        this.cache = {}
        this.cacheClean = false;
    }

    async testAPI() {
        try {
            const response = await fetch(`http://${this.path}:9900/testAPI`)
            const resultText = await response.text()
            return resultText;
        }
        catch (err) {
            console.log(`error with testAPI`, err)
        }
        return false
    }

    async testDB() {
        try {
            const response = await fetch(`http://${this.path}:9900/testDB`)
            const resultText = await response.text()
            return resultText;
        }
        catch (err) {
            console.log(`error with testDB`, err)
        }
        return false
    }

    async getTopics() {
        if (this.cacheClean) {
            let response = Object.assign(this.cache)
            return response
        }
        try {
            const response = await fetch(`http://${this.path}:9900/topics`);
            const result = await response.json();
            this.cache = Object.assign(result);
            this.cacheClean = true;
            return result;
        }
        catch(err) {
            console.log(`error with getTopics`, err)
        }
        return false;
    }

    async putTopics(model, data) {
        const sentData = { docs: data }
        const jsonData = JSON.stringify(sentData)
        try {
            const response = await fetch(`http://${this.path}:9900/topics/${model}`, {
                headers: {'Content-Type': 'application/json'},
                method: 'PUT',
                body: jsonData
            })
            const result = await response.text()
            this.cacheClean = false;
            return result;
        }
        catch(err) {
            console.log(`error with putTopics`, err)
        }
        return false;
    }
}

export default DataService
