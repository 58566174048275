import React, { Component } from 'react';
import Logo from './tic-logo'
import Boxes from './boxes'

class Header extends Component {

    render() {
        return(
            <div className="outer">
                <div className="logo-block">
                    <Logo></Logo>
                    <div>
                        <h2>Technology Impact Consulting, LLC</h2>
                        <p>Assessing the impact of technology on your company's strategy, vision and product
                            development - a&nbsp;view&nbsp;from&nbsp;<strong>Richard&nbsp;P.&nbsp;Hooper,&nbsp;Ph.D</strong></p>
                    </div>
                </div>
                <Boxes></Boxes>
            </div>
        )
    }
}

export default Header;
