const keywords = `\
cloud computing, social media, Twitter, Facebook, location based services,
business process and workflow automation, application development,
strategic planning, systems modeling and architecture, Spring,
Node.js, Zend, Symfony, MySQL, MongoDB, AJAX, Backbone.js,
Angular.js, REACT, HTML5, CSS, SASS, Bootstrap, Foundation, Wordpress,
C++, Java, Python, PHP, Javascript, JQuery,
Android, iPhone/iPad, mobile apps.
`

export default keywords;
