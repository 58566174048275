import React, { Component } from 'react';
import Icon from './icon'
import MessageBlock from './message-block'

class EditData extends Component {

    constructor(props) {
        super(props)
        this.dataService = props.ds
        this.topics = null
        this.initialTopics = null
        this.editBlock = null
    }

    componentWillMount() {
        this.flashMessage('Loading', 'inform')
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        if (this.dataService) {
          this.dataService.getTopics()
          .then( topicData => {
            console.log(`topics data`, topicData)
            this.topics = topicData
            this.initialTopics = JSON.parse(JSON.stringify(this.topics))
            this.choices = []
            for (const [index, value] of this.topics.technologies.entries()) {
                this.choices.push(
                    <li key={index} id={value.id} onClick={this.loadIt.bind(this)}>{value.name ? value.name : value.title}</li>
                )
            };
            this.choices.push(
                <li key={this.topics.technologies.length}><strong><em>Add New</em></strong></li>
            )
            this.setState({ isLoading: false });
          }, err => {
            console.log('home-page could not get db topics', err);
            this.setState({ msg: 'Could not get topics', showMsg: 'show'})
          })
        }
        this.setState({ showMsg: 'hide' })
    }

    loadIt(evt) {
        this.setState({ isEditing: true, hasChanged: false, idToEdit: evt.target.id }, () => {
            this.editBlock = this.createEditBlock()
            this.setState({ isEditing: false })
        })
    }

    changeItem(evt) {
        this.setState({isEditing: true})
        const field = evt.target.name.replace('update','').toLowerCase()
        let whichToChange = this.topics.technologies.findIndex(t => t.id === this.state.idToEdit)
        console.log(`field: ${field}, whichtochange: ${whichToChange}`)
        switch(field) {
            case 'id':
            case 'title':
            case 'name':
            case 'split':
                this.topics.technologies[whichToChange][field] = evt.target.value
                this.setState({ hasChanged: true})
                break;
            case 'description':
                whichToChange = this.topics.descriptions.findIndex(d => d.technology === this.state.idToEdit)
                this.topics.descriptions[whichToChange].description = evt.target.value
                this.setState({ hasChanged: true})
                break;
            default:
        }
        this.editBlock = this.createEditBlock()
        this.setState({isEditing: false})
    }

    submitChange(evt) {
        console.log('submitChange', this.state)
        console.log('latest topics', this.topics)
        const technology = this.topics.technologies.find(t => t.id === this.state.idToEdit)
        const link = this.topics.links.find(t => t.technology === this.state.idToEdit)
        const description = this.topics.descriptions.find(t => t.technology === this.state.idToEdit)
        console.log('about to change', technology, link, description)
        if (this.dataService) {
            Promise.all([
                this.dataService.putTopics('technologies', [technology]),
                this.dataService.putTopics('topicdescriptions', [description]),
                this.dataService.putTopics('topiclinks', [link])
            ])
            .then ( responses => {
                console.log('putTopics responses', responses)

            })
            .catch(err => {
                console.log(`Could not update topic: ${this.state.idToEdit}`, err)
            })
        }
    }

    cancelChange(evt) {
        this.setState({idToEdit: undefined}, () => {
            console.log('cancelChange', this.state)
            this.topics = this.initialTopics
            this.editBlock = this.createEditBlock()
            this.setState({hasChanged: false, isEditing: false, isLoading: false})
        })
    }

    createEditBlock() {
        if (this.state.idToEdit) {
            const idVal = this.state.idToEdit
            const technologyToEdit = this.topics.technologies.find(t => t.id === idVal)
            const description = this.topics.descriptions.find(d => d.technology === idVal).description
            technologyToEdit.name = technologyToEdit.name || ''
            console.log('technologyToEdit', technologyToEdit, this.state.idToEdit)
            return (
                <div className="edit-block">
                    <div className="input-block linear">
                        <label>id:</label>
                        <input type="text" name="updateId" value={idVal} onChange={this.changeItem.bind(this)} />
                    </div>
                    <div className="input-block linear">
                        <label>title:</label>
                        <input type="text" name="updateTitle" value={technologyToEdit.title} onChange={this.changeItem.bind(this)} />
                    </div>
                    <div className="input-block linear">
                        <label>name:</label>
                        <input type="text" name="updateName" value={technologyToEdit.name} onChange={this.changeItem.bind(this)} />
                    </div>
                    <div className="input-block linear">
                        <label>split:</label>
                        <input type="text" name="updateSplit" value={technologyToEdit.split} onChange={this.changeItem.bind(this)} />
                    </div>
                    <div className="input-block">
                        <label>description:</label><br/>
                        <textarea name="updateDescription" value={description} onChange={this.changeItem.bind(this)}></textarea>
                        <div className="topic-icon">
                            <Icon id={idVal} item={technologyToEdit}></Icon>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: description}} />
                    <div className="button-panel">
                        <button className="primary" onClick={this.submitChange.bind(this)} style={{display: (this.state.hasChanged ? 'inline-block' : 'none')}} >Submit</button>
                        <button className="warn" onClick={this.cancelChange.bind(this)}>Cancel</button>
                    </div>
                </div>
            )
        } else {
            return ''
        }
    }

    flashMessage(message, id) {
        this.setState({ msg: message, mType: `edit-error ${id}` })
        this.setState({ showMsg: 'show'}, () => (
            setTimeout( () => {
                this.setState({ showMsg: 'hide'})
            }, 3000)
        ))
    }

    render() {
        return (
            <div className="edit-panel">
                <h2>Edit Page</h2>
                <label>Choose Technology Topic:</label>
                <ul>
                {this.choices}
                </ul>
                {this.editBlock}
                <MessageBlock msgType={this.state.mType} showIt={this.state.showMsg} message={this.state.msg}></MessageBlock>
            </div>
        )
    }
}

export default EditData