import React, { Component } from 'react';

class MessageBlock extends Component {

    render() {
        const cls = { msgType: this.props.msgType }
        if (this.props.showIt === 'show') {
            cls.msgType = this.props.msgType  + ' show'
        } else {
            cls.msgType = this.props.msgType + ' hide'
        }
        return (
            <div className={cls.msgType}>
                {this.props.message}
            </div>
        )
    }
}

export default MessageBlock
