import React, { Component } from 'react';
import keywords from './keywords'

class About extends Component {

    render() {
        const resumeLink = `${process.env.REACT_APP_RESUME_LINK}`
        return (
            <div className="two-column-panel">
                <div className="text-segment wide">
                    <h2>About Technology Impact Consulting, LLC</h2>
                    <p>
                    We provide solutions to problems encountered in the application of
                    current technologies. We operate in web-based and mobile applications
                    in location based services (LBS), video analytics, social media, and
                    business process automation. Dr. Richard P. Hooper, principal, is a 40
                    year veteran in the field of computer sciences, systems architecture,
                    and data modeling. He uses a variety of current technologies to design
                    and/or implement solutions for clients. If you have need solutions for
                    a specific technical issue, need advice, or want a plan for migrating to
                    a new technology, contact us.
                    </p>
                    <h2 style={{marginTop:50 + 'px'}}>keywords:</h2>
                    <p >
                    {keywords}
                    </p>
                    <p style={{marginTop:25 + 'px'}}>
                    <a className="button success" href={resumeLink}
                        target="_blank" rel="noopener noreferrer">Resume of Richard P. Hooper, PhD</a>
                    </p>
                </div>
            </div>
        )
    }

}

export default About;