import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const links = [
    { title: 'About Technology Impact Consulting', link: '/about'},
    { title: 'Gartner IT Glossary', link: 'https://www.gartner.com/it-glossary/'},
    { title: 'Linkedin', link: 'https://www.linkedin.com/company/technology-impact-consulting-llc'},
    { title: 'Twitter', link: 'https://twitter.com/TechImpactCons'},
    { title: 'Facebook', link: 'https://www.facebook.com/Technology-Impact-Consulting-164113913074/'},
    { title: 'Contact Us', link: 'http://www.techimpactcons.com/qrlp.html'},
]

 class Footer extends Component {

    render() {
        const list = []
        let url = ''
        let updatedLinks = []
        Object.assign(updatedLinks,links)
        if (this.props.notMain) {
            updatedLinks.unshift({ title: 'Home', link: '/'} )
        }
        for (const [index, value] of updatedLinks.entries()) {
            if (value.link.indexOf('http') >= 0) {
                url = (<a href={value.link} target="_blank" rel="noopener noreferrer">{value.title}</a>)
                list.push(<li key={index}>{url}</li>)
            } else {
                if (!this.props.isAbout || value.link !== links[0].link) {
                    url = (<Link to={`${process.env.PUBLIC_URL}${value.link}`}>{value.title}</Link>)
                    list.push(<li key={index}>{url}</li>)
                }
            }
        }
        return(
            <footer className="App-footer">
                <ul>{list}</ul>
            </footer>
        )
    }
}

export default Footer;