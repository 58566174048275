
import React, { useEffect, useState, useMemo } from 'react';
import {Routes, Route, Link } from "react-router-dom";

import './App.css';

import DataService from './services/data-service';
import Footer from './segments/footing'
import Header from './segments/heading'
import Home from './segments/home-page'
import About from './segments/about-page'
import WhatsNew from './segments/whats-new';
import EditData from './segments/edit-data';

function App() {
  const [statusIndicator, setStatusIndicator] = useState('red');
  const [dbIndicator, setDbIndicator] = useState('red');
  const [editDisplay, setEditDisplay] = useState('none');
  const [topicData, setTopicData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dataService = useMemo(
    () => new DataService(),
    []
  );

  useEffect(() => {
    const callAPI = async () => {
      const apiResp = await dataService.testAPI();
      setStatusIndicator(await apiResp === 'API is working properly' ? 'green': 'red');
    };

    const callDB = async () => {
      const dbResp = await dataService.testDB();
      setDbIndicator(dbResp === 'Connected to Database' ? 'green': 'red');
    }
    if (dataService) {
      callAPI();
      callDB();
      const showEdit = ['ticremote', 'localhost'].indexOf(window.location.hostname) >= 0;
      const editDisplayAttribute = showEdit ? 'inline' : 'none';
      setEditDisplay(editDisplayAttribute);
    }
  }, [dataService]);

  useEffect(() => {
    if (dataService && topicData.length <= 0) {
      setIsLoading(true);
      dataService.getTopics()
        .then( topicData => {
          setIsLoading(false);
          setTopicData(topicData);
        }, err => {
          setIsLoading(false);
          console.log('home-page could not get db topics', err);
        });
  }
  }, [dataService, topicData]);

  const addData = (model, data) => {
      dataService.putTopics(model, data)
          .then( results => {
              console.log(`${model} added`, results);
          }, err => {
              console.log(`Could not add ${model} data`, data, err)
          })
  };

  const mainPage = () => {
    return (
      <div>
        <Home dataService={topicData} toAdd={addData} />
        <div className="button-panel">
          <Link to="/whats-new">
            <button className="success">See the latest at Technology Impact Consulting!</button>
          </Link>
        </div>
        <Footer isAbout={false} notMain={false} />
      </div>
    );
  };

  const aboutPage = () => {
    return (
      <div>
        <About />
        <div className="button-panel">
        </div>
        <Footer isAbout={true} notMain={true} />
      </div>
    );
  };

  const editPage = () => {
    return (
      <div>
        <EditData ds={dataService}/>
        <Footer isAbout={true} notMain={true} />
      </div>
    )
  };

  return (
    <>
      {isLoading && <p>Loading...</p>}
      <div className="App">
        <Link to={`/`}><header className="App-header"><Header /></header></Link>
        <Routes>
              <Route exact path={`/`} Component={mainPage} />
              <Route path={`/about`} Component={aboutPage} />
              <Route path={'/whats-new'} element={WhatsNew} />
              <Route path={'/edit-data'} Component={editPage} />
          </Routes>
          <p className="App-intro">
                <span style={{ color: statusIndicator }}>&#9729;</span>
                <span style={{ color: dbIndicator }}>&#10070;</span>
                <span style={{ display: editDisplay }}>
                  <Link to={{ pathname: '/edit-data' }}>&#9874;</Link></span>
          </p>
      </div>
    </>
  );

}

export default App;

//delayHide={100} clickable={true} className="tooltip-item"